import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const SuggestedHints = () => {
  // Token and headers
  const token = localStorage.getItem('accessToken');
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const license = localStorage.getItem('currentActiveStoreLicense');

  const [isOnProcess, setIsOnProcess] = useState(false);
  const [editedSuggested, setEditedSuggested] = useState('');
  const [hintMessage, setHintMessage] = useState('');
  const [productExists, setProductExists] = useState(null);
  const [error, setError] = useState(null);

  // Redirect if token is invalid
  useEffect(() => {
    if (!token) {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('menuAccess', []);
      localStorage.setItem('profileRole', '');
      window.location.href = '/login';
    }
  }, [token]);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  // Fetch suggested hints from the server
  const [suggestedHints, setSuggestedHints] = useState([]);

  useEffect(() => {
    fetchTable();
  }, []);

  const fetchTable = async () => {
    const store_id = currentActiveStoreId || 1;
    try {
      const result = await axios.get(`/api/suggested-hints/${store_id}`, config);
      setSuggestedHints(result.data.data);
      setHintMessage(result.data.hint_message);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSuggestedChange = (event) => {
    setEditedSuggested(event.target.value);
  };

  const extractHandleFromUrl = (url) => {
    const match = url.match(/\/variant\/([^\/]*)/);
    return match ? match[1] : null;
  };

  const handleBlur = (suggested) => {
    suggested.hint_url = editedSuggested;
    const data = {
      hint_url: editedSuggested,
      id: suggested.id,
      hints: suggestedHints
    };
    // Call your update API here if needed
  };

  const handleSaveClick = () => {
    setIsOnProcess(true);
    checkAllProducts();
  };

  const checkAllProducts = async () => {
    let localErrorCount = 0; // Use a local variable for tracking errors
    const promises = suggestedHints.map((suggested) => {
      if (suggested.hint_url !== '') {
        return checkProductExists(suggested.hint_url, suggested.id, suggested.myshopify_domain, suggested.access_token, (hasError) => {
          if (hasError) {
            localErrorCount += 1;
          }
        });
      } else {
        const contentDiv = document.getElementById('error-msg-' + suggested.id);
        contentDiv.innerHTML = '<br />'; // Clear previous messages
        return Promise.resolve();
      }
    });

    try {
      await Promise.all(promises);
      console.log('New Error Count:', localErrorCount);

      if (localErrorCount === 0) {
        handleSaveAction();
      } else {
        setIsOnProcess(false);
        // alert('Some URLs are invalid. Please correct them before saving.');
        window.toastr.error('Some URLs are invalid. Please correct them before saving.', 'Oops!');
      }
    } catch (err) {
      setIsOnProcess(false);
      console.error('Error processing products:', err);
    }
  };

  const handleSaveAction = () => {
    setIsOnProcess(true);
    const data = { hints: suggestedHints };

    try {
      axios.post('/api/suggested-hints/update', data, config)
        .then(result => {
          // alert(result.data.message);
          window.toastr.success(result.data.message, 'Good Job!');
          setIsOnProcess(false);
        })
        .catch(err => {
          console.error('API request failed:', err);
          window.toastr.error('API request failed: ' + err, 'Oops!');
          setIsOnProcess(false);
        });
    } catch (e) {
      console.error('Unexpected error:', e);
      window.toastr.error('Unexpected error: ' + e.message, 'Oops!');
      setIsOnProcess(false);
    }
  };

  const checkProductExists = async (product_url, id, domain, token, callback) => {
    const contentDiv = document.getElementById('error-msg-' + id);
    contentDiv.innerHTML = '<br />'; // Clear previous messages
    const urlObj = new URL(product_url);
    const withVariant = urlObj.searchParams.has('variant');
    const variant = urlObj.searchParams.get('variant');

    // if (withVariant) {
      // const variant_url = `https://${domain}/admin/api/2024-07/variants/${variant}.json`;
      // const data = {
      //   url: variant_url,
      //   access_token: token
      // };

      const data = {
        urls: [product_url],
      }

      try {
        // const result = await axios.post('/api/suggested-hints/productdata', data, config);
        // const exists = result.data.isExist;

        const response = await axios.post('https://grip-app-gh3.gyfthint.com/api/check-shopify-url', data, config);
        const exists = response.data.results[product_url];

        if (exists) {
          contentDiv.innerHTML = '<br />';
          callback(false); // No error
        } else {
          contentDiv.innerHTML = ' * Invalid url.';
          callback(true); // Error occurred
        }
      } catch (err) {
        console.error('Error checking product existence:', err);
        contentDiv.innerHTML = ' * Error occurred.';
        callback(true); // Error occurred
      }
    // } else {
    //   contentDiv.innerHTML = '* No variant found from the url.';
    //   callback(true); // Error occurred
    // }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
        <div id="table-list-container" className="col-4">
          <div className="card">
            <h5 className="card-header pb-3">Suggested Products</h5>
            <div className="card-body">
              <div className="col-12">
                {suggestedHints.map(suggested => (
                  <div className="col-sm-11" key={suggested.id}>
                    <input
                      type="text"
                      placeholder="Enter the PDP URL"
                      className="form-control"
                      defaultValue={suggested.hint_url}
                      onChange={handleSuggestedChange}
                      onBlur={() => handleBlur(suggested)}
                    />
                    <div className="textbox-msg-box">
                      <span className="error-msg" id={"error-msg-" + suggested.id}><br /></span>
                    </div>
                  </div>
                ))}
                {!isOnProcess ?
                  <button
                    type="submit"
                    className="btn btn-primary data-submit me-sm-3 me-1"
                    onClick={handleSaveClick}
                  >
                    Save Changes
                  </button> :
                  <button
                    type="button"
                    className="btn btn-primary data-submit me-sm-3 me-1"
                    disabled
                  >
                    Saving...
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div id="table-list-container" className="col-8">
          <div className="card">
            <h5 className="card-header pb-3">{license}</h5>
            <div className="card-body">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: hintMessage }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestedHints;
