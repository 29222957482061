import React, {useEffect} from "react";

const ValueBox = (props) => {
    const isLoading = props.loading;
    const value = props.value;
    const icon = props.icon;
    const label = props.label;
    const iconColor = props.iconColor;
    const comingSoon = props?.comingSoon || false;
    const updates = props?.updates || null;
    const actions = props?.actions || [];
    const svgIcon = props?.svgIcon || null;
    const totalQty = props?.totalQty || null;
    const totalGmv = props?.totalGmv || null;

    // TODO: Convert totalQty and totalGmv to K/M (1K/1M) value

    return (
        <div
            className={`kpi pb-2 gh-value-box d-flex justify-content-between text-start h-100 overflow-hidden position-relative flex-grow-1 ${comingSoon ? 'gh-coming-soon' : ''}`}
            style={{minHeight: '160px', flexDirection: 'column'}}>
            {
                comingSoon && <div className='gh-coming-soon-banner'>Coming Soon</div>
            }
            <div className="d-flex justify-content-start align-items-center"
                 style={{opacity: comingSoon ? '0.4' : '1'}}>
                {
                    icon === 'percent' && <div className="icon"><b>%</b></div>
                }
                {
                    icon !== 'percent' &&
                    <div className="icon">
                        <span className={`menu-icon-page ${icon}`} style={{color: iconColor}}></span>
                    </div>
                }
                <h2 className='m-0' style={{color: '#9291A5', fontSize: '15px', fontWeight: ''}}>{label}</h2>
                {
                    actions && (actions.length > 0) &&
                    <div className="dropdown">
                        <button
                            disabled={comingSoon}
                            className="btn p-0"
                            type="button"
                            id="cardOpt1"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="cardOpt1">
                            {
                                actions.map(a => (
                                    <a className="dropdown-item" href="">{a.label}</a>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>

            <div className="details mb-2 h-100" style={{opacity: comingSoon ? '0.4' : '1'}}>
                {
                    isLoading ?
                        <div className="demo-inline-spacing">
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <span className="increase fs-4 gh-dashboard-values"
                              style={{fontWeight: '600'}}>{value}</span>
                }

                {
                    updates && updates.increase &&
                    <small className="text-success fw-medium">
                        <i className="bx bx-up-arrow-alt"></i> {updates.value}
                    </small>
                }
                {
                    updates && !updates.increase &&
                    <small className="text-danger fw-medium">
                        <i className="bx bx-down-arrow-alt"></i> {updates.value}
                    </small>
                }
            </div>

            {
                (totalGmv || totalQty) && <>
                    <div className='position-relative'
                         style={{borderTop: '1px #E2E2E2 solid', width: 'calc(100% + 40px)', marginLeft: '-20px'}}></div>
                    <div className='position-relative d-flex justify-content-between align-items-center'
                         style={{
                             marginLeft: '-20px',
                             marginRight: '-20px',
                             paddingTop: '6px',
                             paddingLeft: '20px',
                             paddingRight: '20px'
                         }}>
                        <div>
                            <label style={{color: '#9291A5', fontSize: '12px', fontWeight: '600'}}>Qty:</label>
                            <span className="ms-2"
                                  style={{color: '#000', fontSize: '12px', fontWeight: '600'}}>{totalQty}</span>
                        </div>
                        <div>
                            <label style={{color: '#9291A5', fontSize: '12px', fontWeight: '600'}}>GMV:</label>
                            <span className="ms-2" style={{
                                color: '#000',
                                fontSize: '12px',
                                fontWeight: '600'
                            }}>${totalGmv > 0 ? totalGmv.toFixed(2) : '0.00'}</span>
                        </div>
                    </div>
                </>
            }
        </div>

    )
}
export default ValueBox
