import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const Hints = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /* setting of forms */
  const [hints, setHints] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here*/

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        if(err.response.data.message==='Unauthenticated.'){
            window.location.href = '/login';
        } else {
          alert(err.response.data.message);
        }
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    try{
      await axios.get('/api/hints/null', config ).then(result => {
        setHints(result.data.data);
        setLen(result.data.data.length);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New Products');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  }
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header"><span className='bx bx-gift'></span> Products
                {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                  <thead>
                      <tr>
                          <th>Image</th>
                          <th className="th-long">Product Name</th>
                          <th>Product Url</th>
                          <th>Source</th>
                          <th>QTY</th>
                          <th>Price</th>
                          {canEdit===1 && <th>Action</th> }
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {(Array.isArray(hints)?hints:[]).map(hint=>(
                        <tr key={hint.id}> 
                          <td><img src={hint.product_images_links} style={{width:'auto', height:'100px'}}></img></td>
                          <td><span className="fw-medium">{hint.product_name}</span></td>
                          <td><a className="btn btn-primary btn-sm" href={'https://'+hint.product_url + '&q=' + hint.product_quantity} target="_blank">View Product</a></td>
                          <td><span>{hint.source}</span></td>
                          <td><span>{hint.product_quantity}</span></td>
                          <td><span>{hint.product_price}</span></td>
                          {canEdit?<td><a href="#"><i className='bx bx-edit'></i></a></td>:''}
                        </tr>
                      ))}
                      {len===0 && !isLoading &&
                        <tr>
                          <td colSpan={7}>No records found.</td>
                        </tr>
                      }
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
          </div>      
      </div>
    </div>
  )
}

export default Hints