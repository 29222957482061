import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "../api/axios";

const CustomerDetails = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  /* setting of forms */
  const [hints, setHints] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const [storeId, setStoreId] = useState(currentActiveStoreId);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [source, setSource] = useState('');
  const [sourceSubType, setsourceSubType] = useState('');
  const [customerId, setCustomerId] = useState(0);
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here*/

  useEffect(() => {
    fetchModuleDetails();
    fetchDetails();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = '/customers';
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        if(err.response.data.message==='Unauthenticated.'){
            window.location.href = '/login';
        } else {
          alert(err.response.data.message);
        }
      });
    } catch (e){
      console.log(e);
    }
  }
  
  const fetchDetails = async() => {
    setIsLoading(true);
    try{
      await axios.get('/api/customers/details/' + id, config ).then(result => {
        setFirstName(result.data.data[0]['first_name']);
        setLastName(result.data.data[0]['last_name']);
        setBirthDate(result.data.data[0]['birthdate']);
        setEmail(result.data.data[0]['email']);
        setPhone(result.data.data[0]['phone']);
        setSource(result.data.data[0]['source']);
        setsourceSubType(result.data.data[0]['source_subtype']);
        setCustomerId(result.data.data[0]['id']);

        setHints(result.data.hints);
        setLen(result.data.hints.length);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New Products');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  }
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-3">
            <div className="card">
                <h5 className="card-header"><span className='bx bx-list-ul'></span> Customer Details
                <hr /></h5>
                <div className="card-body">
                  <label className="form-label">First Name:</label>
                  <input type="text" className="form-control" defaultValue={firstName} disabled></input>
                  <label className="form-label">Last Name:</label>
                  <input type="text" className="form-control" defaultValue={lastName} disabled></input>
                  <label className="form-label d-none">Birthdate:</label>
                  <input type="text" className="form-control d-none" defaultValue={birthDate} disabled></input>
                  <label className="form-label">Email:</label>
                  <input type="text" className="form-control" defaultValue={email} disabled></input>
                  <label className="form-label">Phone:</label>
                  <input type="text" className="form-control" defaultValue={phone} disabled></input>
                  <label className="form-label">Source:</label>
                  <input type="text" className="form-control" defaultValue={source} disabled></input>
                  <label className="form-label">Source Sub-Type:</label>
                  <input type="text" className="form-control" defaultValue={sourceSubType} disabled></input>
                </div>
            </div>
          </div>
          <div id="table-list-container" className="col-md-9">
            <div className="card">
                <h5 className="card-header"><span className='bx bx-gift'></span> Products
                <a className="btn btn-primary btn-sm ms-2" href="/customers" > Back &nbsp;</a>
                {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2 d-none" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                      <tr>
                          <th>Image</th>
                          <th className="th-long">Product Name</th>
                          <th>Product Url</th>
                          <th>Source</th>
                          <th>QTY</th>
                          <th>Price</th>
                          {canEdit===1 && <th>Action</th> }
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {(Array.isArray(hints)?hints:[]).map(hint=>(
                        <tr key={hint.id}> 
                          <td><img src={hint.product_images_links} style={{width:'auto', height:'100px'}}></img></td>
                          <td><span className="fw-medium">{hint.product_name}</span></td>
                          <td><a className="btn btn-primary btn-sm" href={'https://'+hint.product_url + '&q=' + hint.product_quantity}>View Product</a></td>
                          <td><span>{hint.source}</span></td>
                          <td><span>{hint.product_quantity}</span></td>
                          <td><span>{hint.product_price}</span></td>
                          {canEdit?<td><a href="#"><i className='bx bx-edit'></i></a></td>:''}
                        </tr>
                      ))}
                      {len===0 && !isLoading &&
                        <tr>
                          <td colSpan={7}>No records found.</td>
                        </tr>
                      }
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div className="card">
                <h5 className="card-header"><span className='bx bx-package'></span> Orders
                </h5>
                <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Store</th>
                      <th>Product Description</th>
                      <th>Date</th>
                      <th>Recipient</th>
                      <th>Buyer</th>
                      <th>GMV</th>
                      <th>Fulfillment Status</th>
                      <th>Payment</th>
                      <th>Confirmed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>06/02/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$180.00</td>
                      <td><span className="badge bg-label-warning me-1">Unfulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>06/24/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$14.90</td>
                      <td><span className="badge bg-label-warning me-1">Unfulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>06/02/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$0.00</td>
                      <td><span className="badge bg-label-warning me-1">Unfulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/01/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$74.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/22/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$59.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>no</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/27/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$51.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/27/2024</td>
                      <td>vitor customer cancelled closed</td>
                      <td>John Smith</td>
                      <td>$44.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/27/2024</td>
                      <td>Vitor Reward</td>
                      <td>Peter Smith</td>
                      <td>$38.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>no</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/28/2024</td>
                      <td>Reward Discount</td>
                      <td>John Smith</td>
                      <td>$38.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/28/2024</td>
                      <td>Reward Discount</td>
                      <td>John Smith</td>
                      <td>$14.90</td>
                      <td><span className="badge bg-label-warning me-1">Unfulfilled</span></td>
                      <td></td>
                      <td>no</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/28/2024</td>
                      <td>Vitor Guest</td>
                      <td>Peter Smith</td>
                      <td>$44.90</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>no</td>
                    </tr>
                    <tr>
                      <td>gyft-neil</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                      <td>07/29/2024</td>
                      <td>Customer Test tester</td>
                      <td>Peter Smith</td>
                      <td>$30.00</td>
                      <td><span className="badge bg-label-secondary me-1">Fulfilled</span></td>
                      <td></td>
                      <td>yes</td>
                    </tr>
                  </tbody>
                </table>
                </div>
          </div>
          </div>      
          
      </div>
    </div>
  )
}

export default CustomerDetails