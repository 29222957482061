import React, {useEffect, useRef} from "react";

const DateRange = ({openDateRangeFilter, setOpenDateRangeFilter, currentFilters, handleFilterByDateFrom, handleFilterByDateTo}) => {
    const dateRangePopupRef = useRef(null);

    const handleClickOutside = (event) => {
        // Check if the click is outside the <ul>
        if (dateRangePopupRef.current && !dateRangePopupRef.current.contains(event.target)) {
            setOpenDateRangeFilter(false); // Close the dropdown or perform your action
        }
    };

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <ul ref={dateRangePopupRef} className={`dropdown-menu ${openDateRangeFilter ? 'show' : ''}`}>
                <li className="dropdown-item">
                    <div className='d-flex align-items-center mb-2'>
                        <label className='me-2 w-25'>From: </label>
                        <input type="date" name="start" className="form-control"
                               style={{maxWidth: '150px', minWidth: '150px'}}
                               max={currentFilters.filterDateTo || ''}
                               value={currentFilters.filterDateFrom || ''}
                               onChange={(e) => {
                                   handleFilterByDateFrom(e.target.value);
                               }}></input>
                    </div>
                    <div className='d-flex align-items-center'>
                        <label className='me-2 w-25'>To: </label>
                        <input type="date" name="start" className="form-control"
                               style={{maxWidth: '150px', minWidth: '150px'}}
                               min={currentFilters.filterDateFrom || ''}
                               value={currentFilters.filterDateTo || ''}
                               onChange={(e) => {
                                   handleFilterByDateTo(e.target.value);
                               }}></input>
                    </div>
                </li>
            </ul>
        </>
    )
}
export default DateRange
