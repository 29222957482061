import { useEffect, useState } from "react"
import axios from "../api/axios";
//import { useNavigate } from "react-router-dom";

const LoginByPhone = () => {
  const [phone, setPhone] = useState("");
  const [isOnProcess, setIsOnProcess]=useState(false);
  const [redirectUrl, setRedirectUrl]=useState(null);

  //const navigate = useNavigate();
  useEffect(() => {
    // Get the query parameter named "paramName" from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get('redirect_url');

    // Update the state with the parameter value
    setRedirectUrl(value);
    //console.log('Redirect URL : ' + redirectUrl);
  }, []); // Empty dependency array to run the effect only once on component mount

  const handleLogin = async(event) => {
    event.preventDefault();
    setIsOnProcess(true);
    try{
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/loginbyphone', {phone}).then(result =>{
          setPhone("");
          localStorage.setItem('accessToken', result.data.token);
          localStorage.setItem('userId', result.data.user_id);
          localStorage.setItem('id', result.data.id);
          localStorage.setItem('profileRole', result.data.menu_access[0]['role_name']);
          localStorage.setItem('menuAccess', JSON.stringify(result.data.menu_access));
          localStorage.setItem('currentModule', 'Dashboard');
          localStorage.setItem('stores', JSON.stringify(result.data.stores));
          localStorage.setItem('role', result.data.role);
          if(result.data.stores.length > 0){
            localStorage.setItem('currentActiveStoreId', result.data.stores[0].uuid);
            localStorage.setItem('currentActiveStoreLicense', result.data.stores[0].label);
            localStorage.setItem('currentActiveStore', result.data.stores[0].store_name);
            localStorage.setItem('currentActiveStoreLogo', result.data.stores[0].store_logo);
          } else {
            localStorage.setItem('currentActiveStoreId', '');
            localStorage.setItem('currentActiveStoreId', result.data.stores[0].uuid);
            localStorage.setItem('currentActiveStoreLicense', '');
            localStorage.setItem('currentActiveStore', null);
            localStorage.setItem('currentActiveStoreLogo', null);
          }
          if(redirectUrl === null){
            alert("Can't find a redirect url.");
            setIsOnProcess(false);
          } else {
            const newRedirectUrl = redirectUrl + '?token=' + result.data.user_id;
            window.location.href = newRedirectUrl;
          }
        }, (err)=>{
          if(err.response.data.message==='Unauthenticated.'){
              window.location.href = '/login';
          } else {
            alert(err.response.data.message);
          }
          setIsOnProcess(false);
        });
      });
    } catch (e){
      console.log(e);
      setIsOnProcess(false);
    }
  }
  return (
    <div className="container-xxl align-items-center">
      <div className="authentication-wrapper authentication-basic container-p-y login-form-center">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="/login" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <div className="logo-img text-center">
                        <img className="app-brand-logo-app" alt="" src="../gyfhint-logo.png"></img>
                    </div>
                  </span>
                </a>
              </div>
              <br/>
              <h5 className="mb-2">Welcome to GyftHint! 👋</h5>
              <p className="mb-4">Please sign-in to your account using your phone number.</p>
              <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                <div className="mb-3">
                    <label className="form-label">Phone</label>
                    <div className="input-group input-group-merge">
                    <span id="phone" className="input-group-text"><i className="bx bxs-phone"></i></span>
                    <input
                      type="text"
                      value={phone}
                      onChange={(e)=>setPhone(e.target.value)}
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Enter your phone number"
                      required
                      autoFocus>
                    </input>
                    </div>
                </div>
                <br />
                <div className="mb-3">
                  { !isOnProcess?
                    <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>:
                    <button className="btn btn-primary d-grid w-100" disabled type="submit">Loading...</button>
                  }
                </div>
              </form>

              <p className="text-center d-none">
                <span>New on our platform?</span>
                <a href="/merchant-signup">
                  <span>Create an account</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginByPhone