import React, {useState, useEffect} from "react";
import axios from "../api/axios";
import direct_axios from 'axios';
import ValueBox from "../components/ValueBox";

const Orders = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [len, setLen] = useState(0);
  const [affiliateDetails, setAffiliateDetails] = useState([]);
  const [commisionRate, setCommissionRate] = useState(0);
  const stores = JSON.parse(localStorage.getItem('stores'));
  const [totalGMV, setTotalGMV] = useState('0.00');
  const [totalComission, setTotalComission] = useState('0.00');
  const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
  const [selectedDate, setSelectedDate] = useState('');


  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const currentActiveStoreName = localStorage.getItem('currentActiveStore');
  let store_id = currentActiveStoreId == '' || currentActiveStoreId == undefined ? 1: currentActiveStoreId;
  let store_name = currentActiveStoreName == '' || currentActiveStoreName == undefined ? '': currentActiveStoreName;
  const [selectedStore, setSelectedStore] = useState(currentActiveStoreId);
  const [storeId, setStoreId] = useState(store_id);
  const [storeName, setStoreName] = useState(store_name);

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    fetchTable();
    setIsLoading(true);
  }, []);

  
  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];  // Get current date in 'YYYY-MM-DD' format
    setSelectedDate(currentDate); // Set it as the default value
  }, []);

  useEffect(() => {
    // Trigger fetchTable when storeId changes
    fetchTable();
  }, [storeId]);

  /*
  const fetchTable = async () => {
    // Set loading state and clear previous orders
    setIsLoading(true);
    setOrders([]);

    // Define the URL for the API request
    const url = '/api/order/listbystore2/' + store_id;

    try {
      // Fetch data from the API
      const result = await axios.get(url, config);

      // Set loading state to false once the request completes
      setIsLoading(false);

      // Access and set the orders data
      setOrders(result.data.data.data);
      setLen(result.data.data.data.length>0?result.data.data.data.length:0);

      // Access and set the affiliate details
      const affiliateData = result.data.affiliate;
      setAffiliateDetails(affiliateData);

      // Set the commission rate (affiliate_commission_percentage)
      setCommissionRate(affiliateData.affiliate_commission_percentage);

      //Get Hint for now to get total GMV for this storeid
      let external_url = store_id===null?external_api+'/hint':external_api+'/hint?store_id='+store_id;
      try{
        await direct_axios.get(external_url, config ).then(result => {
          console.log(result);
          //setHints(result.data.data);
          //setLen(result.data.data.length);
          //setIsLoading(false);

          const total = result.data.data.reduce((sum, product) => sum + product.price, 0);
          const totalComission = (affiliateData.affiliate_commission_percentage/100) * total;
          setTotalGMV(total);
          setTotalComission(totalComission);
          //console.log(result);
        })
      }catch(e){
        console.log(e);
        setIsLoading(false);
      }

    } catch (e) {
      // Handle error by logging it and resetting loading state
      console.log(e);
      setIsLoading(false);
    }
  };
  */

  const fetchTable = async () => {
    // Set loading state and clear previous orders
    setIsLoading(true);
    setOrders([]);

    // Define the URL for the API request
    const url = '/api/affiliates/listbystore/' + store_id;

    try {
      // Fetch data from the API
      const result = await axios.get(url, config);

      // Access and set the orders data

      // Access and set the affiliate details
      const affiliateData = result.data.data[0];
      setAffiliateDetails(affiliateData);

      // Set the commission rate (affiliate_commission_percentage)
      setCommissionRate(affiliateData.affiliate_commission_percentage);

      //Get Hint for now to get total GMV for this storeid
      let external_url = store_id===null?external_api+'/order':external_api+'/order?store='+storeName+'&status=PURCHASED';
      console.log(external_url);
      try{
        await direct_axios.get(external_url, config ).then(result => {
          console.log(result);
          const total = result.data.data.reduce((sum, product) => {
            // Check if the product's hint is not 'CANCEL' before adding its price
            if (product.status !== 'CANCEL') {
              return sum + product.hint.price;
            }
            return sum;
          }, 0);
          
          setOrders(result.data.data);
          setLen(result.data.data.length>0?result.data.data.length:0);
          setTotalGMV(total);
          const totalCom = (affiliateData.affiliate_commission_percentage/100) * total;
          setTotalComission(totalCom);
          setIsLoading(false);
        })
      }catch(e){
        console.log(e);
        setIsLoading(false);
      }

    } catch (e) {
      // Handle error by logging it and resetting loading state
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleChange = async (event) => {
    const newStoreId = event.target.value;
    setStoreId(newStoreId); // This will trigger useEffect that calls fetchTable
    setSelectedStore(newStoreId);
    localStorage.setItem('currentActiveStoreId', newStoreId);
  };

  function formatDate(date) {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
        <div className="row w-100 m-0 justify-content-between">
            <ValueBox
                label={'Total Orders'}
                value={len.toLocaleString()}
                loading={isLoading}
                icon={'tf-icons bx bx-package'}
                iconColor={'#93C05C'}
            />
            <ValueBox
                label={'Total GMV'}
                value={totalGMV > 0 ? `$${totalGMV.toFixed(2)}` : '$0.00'}
                loading={isLoading}
                icon={'bx bx-line-chart'}
                iconColor={'#FE91DA'}
            />
            <ValueBox
                label={'Conversion Rate'}
                value={'0%'}
                loading={isLoading}
                icon={'tf-icons bx bx-chart'}
                iconColor={'#CE73E0'}
                comingSoon={true}
            />
            <ValueBox
                label={'Total Commisions'}
                value={totalComission > 0 ? `$${totalComission.toFixed(2)}` : '$0.00'}
                loading={isLoading}
                icon={'bx bx-money'}
                iconColor={'#79CC13'}
            />
            <ValueBox
                label={'Commission Rate'}
                value={commisionRate + '%'}
                loading={isLoading}
                icon={'tf-icons fas fa-percent'}
                iconColor={'#FF7D80'}
            />
        </div>
      </div>
      {role.toLowerCase() === 'admin'&&
      <div className="main-kpis">
          <select className="form-select border border-default rounded w-20 ms-1" onChange={handleChange} value={selectedStore}>
            <option value="">All Stores</option>
            {stores.map((store, index) => (
              <option key={index} value={store.uuid}>
                {store.store_name}
              </option>
            ))}
          </select>
      </div>
      }
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header">
                  <div className="kpi-card-info">
                    <span>Gyfthint</span>
                    <p>Orders</p>
                  </div>
                </h5>
                <div className="table-responsive text-nowrap">
                <hr/>
                <div className='d-flex'>
                      <div className="dropdown me-1">
                          <button disabled={true} className="btn dropdown-toggle gh-select-store" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="d-flex justify-content-center align-items-center">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M6.99967 13.6673C3.31777 13.6673 0.333008 10.6825 0.333008 7.00065C0.333008 3.31875 3.31777 0.333984 6.99967 0.333984C10.6815 0.333984 13.6663 3.31875 13.6663 7.00065C13.6663 10.6825 10.6815 13.6673 6.99967 13.6673ZM6.99967 12.334C9.94521 12.334 12.333 9.94618 12.333 7.00065C12.333 4.05513 9.94521 1.66732 6.99967 1.66732C4.05415 1.66732 1.66634 4.05513 1.66634 7.00065C1.66634 9.94618 4.05415 12.334 6.99967 12.334ZM7.66634 7.00065H10.333V8.33398H6.33301V3.66732H7.66634V7.00065Z"
                                          fill="#4B5563"/>
                                  </svg>
                                  <span className='ms-2'>Date Range</span>
                              </div>
                          </button>
                          <ul className="dropdown-menu">
                              {/*<li className="dropdown-item" onClick={() => {handleFilterByCampaign('all')}}>All Campaigns</li>*/}
                          </ul>
                      </div>
                      
                      <div className="dropdown mx-1">
                        <button className="btn dropdown-toggle gh-select-store" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex justify-content-center align-items-center">
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.25467 0C6.50713 0 6.738 0.142667 6.85093 0.368527L7.33333 1.33333H11.3333C11.7015 1.33333 12 1.63181 12 2V9.33333C12 9.70153 11.7015 10 11.3333 10H7.07867C6.8262 10 6.59533 9.85733 6.4824 9.63147L6 8.66667H1.33333V12.6667H0V0H6.25467ZM5.8426 1.33333H1.33333V7.33333H6.82407L7.49073 8.66667H10.6667V2.66667H6.50927L5.8426 1.33333Z"
                                        fill="#4B5563"/>
                                </svg>
                                <span className='ms-2'>Fulfillment Status</span>
                            </div>
                        </button>
                        <ul className="dropdown-menu">
                            <li className="dropdown-item">All Status</li>
                        </ul>
                    </div>  
                  </div>
                  <br/>
                <table className="table">
                  <thead>
                    <tr>
                      {role.toLowerCase() === 'admin' && <th>Store</th>}
                      <th>Product Name</th>
                      <th>Date</th>
                      <th>Recipient</th>
                      <th>Buyer</th>
                      <th className="th_decimal_values">GMV</th>
                      <th className="th_decimal_values">Commission</th>
                      <th>Status</th>
                      <th className="th_decimal_values">Payment Received</th>
                      {role.toLowerCase() === 'admin' && <th>Confirmed</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {(Array.isArray(orders)?orders:[]).map(order=>(
                      <tr key={order.id}>
                        {role.toLocaleLowerCase() === 'admin'&&<td>{order.hint.store}</td>}
                        <td><span style={{ display: 'block', maxWidth: '370px', whiteSpace: 'wrap' }} >{order.hint.name}</span></td>
                        <td>{formatDate(order.created_at)}</td>
                        <td>{order.purchased_by_user['first_name'] + ' ' + order.purchased_by_user['last_name']}</td>
                        <td>{order.purchased_for_user['first_name'] + ' ' + order.purchased_for_user['last_name']}</td>
                        <td className="td_decimal_values">${order.hint.price>0?order.hint.price.toFixed(2):'0.00'}</td>
                        <td className="td_decimal_values">${order.hint.price>0?(order.hint.price * (commisionRate/100)).toFixed(2):'0.00'}</td>
                        <td>{order.status==='CANCELED'?<span className="badge bg-label-danger me-1">{order.status}</span>:<span className="badge bg-label-success me-1">{order.status}</span>}</td>
                        <td className="td_decimal_values">{'$' + order.received_amount.toFixed(2).toLocaleString()}</td>
                        {role.toLocaleLowerCase()=='admin' && 
                          <td>{order.payment_status = 'succeeded'?<span className="badge bg-label-primary me-1">yes</span>:<span className="badge bg-label-danger me-1">no</span>}</td>
                        }
                      </tr>
                    ))} 
                    {len===0 && !isLoading &&
                      <tr>
                        <td colSpan={9}>No records found.</td>
                      </tr>
                    }
                    {isLoading &&
                      <tr>{role.toLowerCase()==='admin' && <td><div className="loading-content"><h5 className="loading-long"></h5></div></td>}<td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    }
                    {isLoading &&
                      <tr>{role.toLowerCase()==='admin' && <td><div className="loading-content"><h5 className="loading-short"></h5></div></td>}<td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                    }
                    {isLoading &&
                      <tr>{role.toLowerCase()==='admin' &&<td><div className="loading-content"><h5 className="loading-long"></h5></div></td>}<td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    }
                  </tbody>
                </table>
                </div>
                <br/>
          </div>
          </div>
        </div>
      </div>
  )
}

export default Orders
